
import CoreFormsService from "@/modules/core-forms/core-forms-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            CoreFormsService.create(payload).then(response => {
                resolve({
                    message: "Forms criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar Forms",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            CoreFormsService.update(payload.id,payload).then(response => {
                resolve({
                    message: "Forms atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar Forms",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            CoreFormsService.remove(id).then(response => {
                resolve({
                    message: "Forms removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover Forms",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            CoreFormsService.delete(id).then(response => {
                resolve({
                    message: "Forms deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar Forms",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            CoreFormsService.get(id).then(response => {
                resolve({
                    message: "Forms encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Forms",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            CoreFormsService.paginate(query).then(response => {
                resolve({
                    message: "Forms encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Forms",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            CoreFormsService.all().then(response => {
                resolve({
                    message: "Forms encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar Forms",
                    success: false,
                    error
                });
            })
        })
    }
}
