
import axios from "axios";

const domain = process.env.VUE_APP_DOMAIN
axios.defaults.baseURL = domain;

export default {
    create(payload) {
        return axios.post("v1_core_form/v1/core_forms/create", payload);
    },
    update(id, payload) {
        return axios.put(`v1_core_form/v1/core_forms/update/${id}`, payload);
    },
    remove(id) {
        return axios.delete(`v1_core_form/v1/core_forms/remove/${id}`);
    },
    delete(id) {
        return axios.delete(`v1_core_form/v1/core_forms/delete/${id}`);
    },
    get(id) {
        return axios.get(`v1_core_form/v1/core_forms/${id}`);
    },
    paginate(query) {
        return axios.get(`v1_core_form/v1/core_forms/paginate`, { params: query });
    },
    all() {
        return axios.get(`v1_core_form/v1/core_forms/`);
    }
}
