<script>

import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import CoreFormsStore from "@/modules/core-forms/core-forms-store";
import CoreFormsPaginate from "@/components/paginate.vue";
import Swal from "sweetalert2";
import CoreFormsDelete from "@/modules/core-forms/components/core-forms-delete.vue";
import CoreFormsRemove from "@/modules/core-forms/components/core-forms-remove.vue";
import CoreFormsShow from "@/modules/core-forms/components/core-forms-show.vue";
import coreFormsStore from "@/modules/core-forms/core-forms-store";

export default {
  name: "Formulario",
  data() {
    return {
      title: "Formularios",
      single: "Formulario",
      items: [
        {text: "Home", to: {name: "Home"}},
        {text: "Formularios", active: true},
      ],
      table: [
        {label: "ID", key: "id"},
        {label: "Nome", key: "name"},
        {label: "E-mail", key: "email"},
        {label: "DDD", key: "ddd"},
        {label: "Telefone", key: "phone"},
        {label: "Assunto", key: "subject"},
        {label: "Mensagem", key: "message"},
        {label: "Data", key: "calendar_date"},
        {label: "Criado", key: "created_at"},
        {label: "Atualização", key: "updated_at"},
        {label: "...", key: "btn"},
      ],
      list: [],
      showNewForm: false,
      loadingForm: false,
      paginate: {
        total_pages: 1,
        page: 1,
        perPage: 10,
        total: 0,
      },
      loadingPage: false,
      buscar: "",
      form: {
        id: null,
        name: null,
        email: null,
        subject: null,
        message: null,
        ddd: null,
        phone: null,
        calendar_date: null,
      },
    }
  },
  components: {CoreFormsShow, CoreFormsRemove, CoreFormsDelete, CoreFormsPaginate, Layout, PageHeader},
  mounted() {
    this.getPaginate(this.paginate.page);
  },
  methods: {
    getPaginate(e = 1) {
      this.paginate.page = e;
      this.loadingPage = true;
      CoreFormsStore.paginate({
        page: this.paginate.page,
        limit: this.paginate.perPage,
        search: this.buscar,
      }).then((response) => {
        const {data, limit, page, total, total_pages} = response.data;
        this.list = data;
        this.paginate.total = total;
        this.paginate.perPage = limit;
        this.paginate.page = page;
        this.paginate.total_pages = total_pages;
      }).catch((error) => {
        console.log(error);
      }).finally(() => {
        this.loadingPage = false;
      });
    },
    cleanForm() {
      this.form = {
        id: null,
        name: null,
        email: null,
        subject: null,
        message: null,
        ddd: null,
        phone: null,
        calendar_date: null,
      }
    },
    edit(item) {
      this.form = item;
      this.showNewForm = true;
    },
    save(event) {
      event.preventDefault();
      this.loadingForm = true;
      if (this.form.id) {
        CoreFormsStore.update(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Atualizado",
            text: "Formulário atualizado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao atualizar",
            text: "Erro ao atualizar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      } else {
        coreFormsStore.create(this.form).then((response) => {
          this.form = response.data;
          this.getPaginate(1);
          this.cleanForm();
          this.showNewForm = false;
          Swal.fire({
            icon: "success",
            title: "Criado",
            text: "Pedido criado com sucesso",
          });
        }).catch((error) => {
          console.log(error);
          Swal.fire({
            icon: "error",
            title: "Erro ao criar",
            text: "Erro ao criar o pedido",
          });
        }).finally(() => {
          this.loadingForm = false;
        });
      }
    }
  }
}
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items"/>
    <b-overlay :show="loadingPage">
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h1>{{ title }}</h1>

              <div class="row mb-4 align-items-center">
                <div class="col-md-5">
                  <div class="row align-items-center">
                    <div class="col-md-6">
                      <input
                          v-model="buscar"
                          type="text"
                          placeholder="Pesquisar...."
                          class="form-control"
                          @change="getPaginate(1)"
                      />
                      <span class="d-block d-md-none"></span>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="getPaginate(1)" type="button" class="btn btn-light btn-block">
                        <i class="fa fa-search"></i> Buscar
                      </button>
                    </div>
                    <div class="col-6 col-md-3">
                      <button @click="buscar=null; getPaginate(1) " class="btn btn-light btn-block">
                        <i class="fa fa-broom"></i> Limpar
                      </button>
                    </div>
                  </div>
                </div>

                <div class="col-md-7 mb-2 text-right align-items-center">
                  <span class="d-block d-md-none p-2"> </span>
                  <div>
                    <button class="btn btn-primary" @click="showNewForm = true">
                      <i class="fa fa-plus"></i>
                      <span v-if="form.id">Editando</span>
                      <span v-if="!form.id">Novo</span>
                    </button>
                  </div>
                  <div v-show="showNewForm">
                    <div class="box-form text-left">
                      <div id="form-novo-edit">
                        <i @click="showNewForm = false; cleanForm();" class="fa float-right fa-2x fa-eye-slash"></i>
                        <h1 class="mt-5 mb-5" v-if="!form.id">Nova {{ single }}</h1>
                        <h1 class="mt-5 mb-5" v-if="form.id">Editando {{ single }}</h1>
                        <div>
                          <b-overlay :show="loadingForm">
                            <b-form @submit="save" class="was-validated">



                              <b-form-group label="Nome" label-for="input-0" description="Nome">
                                <b-form-input id="input-0" v-model="form.name" required></b-form-input>
                              </b-form-group>

                              <b-form-group label="E-mail" label-for="input-1" description="E-mail">
                                <b-form-input id="input-1" v-model="form.email" required></b-form-input>
                              </b-form-group>

                              <b-form-group label="Assunto" label-for="input-1" description="Assunto">
                                <b-form-input id="input-1" v-model="form.subject" required></b-form-input>
                              </b-form-group>

                              <b-form-group label="DDD" label-for="input-1" description="DDD">
                                <b-form-input id="input-1" v-model="form.ddd" required></b-form-input>
                              </b-form-group>

                              <b-form-group label="Telefone" label-for="input-1" description="Telefone">
                                <b-form-input id="input-1" v-model="form.phone" required></b-form-input>
                              </b-form-group>

                              <b-form-group label="Mensagem" label-for="input-1" description="Mensagem">
                                <b-form-textarea id="input-1" v-model="form.message" required></b-form-textarea>
                              </b-form-group>

                              <b-form-group label="Data" label-for="input-1" description="Data">
                                <b-form-select id="input-1" v-model="form.calendar_date" required></b-form-select>
                              </b-form-group>

 
                              <b-button type="submit" variant="primary">
                                <span v-if="form.id">Salvar alterações</span>
                                <span v-if="!form.id">Cadastrar novo</span>
                              </b-button>
                            </b-form>
                          </b-overlay>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <core-forms-paginate :paginate="paginate" @change-page="getPaginate"></core-forms-paginate>
              <b-table striped hover :items="list" :fields="table">
                <template v-slot:cell(updated_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(created_at)="data">
                  {{ data.item.created_at | f_date_time }}
                </template>
                <template v-slot:cell(btn)="data">
                  <button @click="edit(data.item)" class="btn pl-4 pr-4 btn-sm btn-info"><i class="fa fa-edit"></i> Editar</button>
                  <core-forms-delete :value="data.item" @change="getPaginate(1)"></core-forms-delete>
                  <core-forms-remove :value="data.item" @change="getPaginate(1)"></core-forms-remove>
                  <core-forms-show :value="data.item"></core-forms-show>
                </template>
              </b-table>
              <core-forms-paginate :paginate="paginate" @change-page="getPaginate"></core-forms-paginate>
            </div>
          </div>
        </div>
      </div>
    </b-overlay>
  </Layout>
</template>

<style scoped>

</style>

